import jQuery from 'jquery';

export default function popupCta() {
  const $ = jQuery.noConflict();

  $('.popup-cta-trigger').magnificPopup({
    type: 'inline',
    fixedContentPos: true,
    mainClass: 'mfp-fade',
    items: {
      src: '#popup-cta',
    },
  });

  $(document).on('click', '.popup-back', function () {
    $.magnificPopup.close();
  });

  $(document).on('click', '.popup-nav_button._prev', function () {
    $.magnificPopup.close();
  });

  function popupValidation() {
    const radioInputs = document.querySelectorAll('.product_list input[type="radio"]');
    const popupTrigger = document.querySelector('.js-radio-validation');
    const productList = document.querySelector('.product_list');

    function checkRadio() {
      const isAnyChecked = Array.from(radioInputs).some((input) => input.checked);
      const hasError = !isAnyChecked;
      productList.classList.toggle('_error', hasError);

      if (hasError) {
        popupTrigger.setAttribute('disabled', 'disabled');
      } else {
        popupTrigger.removeAttribute('disabled');
      }
    }

    popupTrigger.addEventListener('mousedown', checkRadio);

    radioInputs.forEach((input) => {
      input.addEventListener('change', checkRadio);
    });
  }

  popupValidation();
}
