import jQuery from 'jquery';

export default function popupLogin() {
  const $ = jQuery.noConflict();

  $('.popup-login-trigger').magnificPopup({
    type: 'inline',
    fixedContentPos: true,
    mainClass: 'mfp-fade',
    items: {
      src: '#login',
    },
  });
}
