export default function headerMenu() {
  const openMenuTrigger = document.querySelector('.menu-opener');

  openMenuTrigger.addEventListener('click', () => {
    const footerButton = document.querySelector('.footer-fixed-wrap');

    if (footerButton?.classList.contains('show')) {
      footerButton.classList.remove('show');
      footerButton.dataset.hadShowClass = 'true';
    } else if (footerButton?.dataset.hadShowClass === 'true') {
      footerButton.classList.add('show');
      footerButton.dataset.hadShowClass = 'false';
    }

    document.body.classList.toggle('menu-active');
  });
}
