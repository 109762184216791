export default function starsRating() {
  const starsContainers = document.querySelectorAll('.stars-rating');

  starsContainers.forEach((starsContainer) => {
    const rating = parseFloat(starsContainer.getAttribute('data-rating'));
    const roundedRating = Math.round(rating);

    for (let i = 0; i < roundedRating; i++) {
      const starIcon = document.createElement('i');
      starIcon.className = 'icon-star';
      starsContainer.appendChild(starIcon);
    }
  });
}
