export default function isElementExist(el, cb) {
  var elem = document.querySelector(el);

  if (document.body.contains(elem)) {
    try {
      cb();
    } catch (e) {
      console.log(e);
    }
  }
}
