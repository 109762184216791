import Accordion from 'accordion-js';

export default function accordion({ containerClass, elementClass, triggerClass, panelClass, activeClass }) {
  new Accordion(containerClass, {
    elementClass,
    triggerClass,
    panelClass,
    activeClass,
    duration: 300,
  });
}
