import jQuery from 'jquery';
import jcf from 'jcf';

export default function initJcf() {
  const $ = jQuery.noConflict();
  var customSelect = jQuery('select:not(.stars)');
  var customCheckbox = jQuery('input[type="checkbox"]');
  var customRadio = jQuery('input[type="radio"]');

  customSelect.each(function () {
    $(this).find('option').first().addClass('placeholder');
  });

  // all option see https://github.com/w3co/jcf
  jcf.setOptions('Select', {
    wrapNative: false,
    wrapNativeOnMobile: false,
    fakeDropInBody: false,
    maxVisibleItems: 6,
  });

  jcf.setOptions('Checkbox', {});

  jcf.setOptions('Radio', {});

  // init only after option
  jcf.replace(customSelect);
  jcf.replace(customCheckbox);
  jcf.replace(customRadio);
}
