export default function togglePassword() {
  const inputsWrapper = document.querySelectorAll('.input-password');

  inputsWrapper.forEach((item) => {
    const input = item.querySelector('input[type=password]');
    const toggleVisibilityButton = item.querySelector('.icon-visibility-off');

    toggleVisibilityButton.addEventListener('click', () => {
      if (!input.value) return;

      if (input.type === 'password') {
        input.type = 'text';
      } else {
        input.type = 'password';
      }
    });
  });
}
