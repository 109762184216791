import { Swiper, Navigation, Thumbs } from 'swiper';

export default function quotesSlider() {
  const sliderWrapper = document.querySelectorAll('.quotes');

  sliderWrapper.forEach((item) => {
    let activeSlideIndex = 2;
    const visibleSlides = 5;

    const slider = item.querySelector('.swiper');
    const thumbs = item.querySelector('.quotes-thumbs.swiper');

    window.swiper = {};

    window.swiper.quotesThumbs = new Swiper(thumbs, {
      slidesPerView: 5,
      loop: true,
      spaceBetween: 20,
      preventClicksPropagation: true,
    });

    window.swiper.quotes = new Swiper(slider, {
      modules: [Navigation, Thumbs],
      loop: true,
      navigation: {
        nextEl: '.quotes_control.next',
        prevEl: '.quotes_control.prev',
      },
      effect: 'fade',
      slidesPerView: 1,
      autoHeight: true,
      thumbs: {
        swiper: window.swiper.quotesThumbs,
      },
      on: {
        init: function () {
          this.slidesEl.setAttribute('data-init', 'false');
          this.slideTo(activeSlideIndex);
        },
        slideChange: function () {
          const thumbsWrapper = this.thumbs.swiper.slidesEl;
          const thumbs = thumbsWrapper.querySelectorAll('.swiper-slide');
          const activeThumb = thumbsWrapper.querySelector('.swiper-slide-thumb-active');
          const nextActiveThumb = activeThumb.nextElementSibling;
          const prevActiveThumb = activeThumb.previousElementSibling;

          thumbs.forEach((item) => item.classList.remove('_size-md'));

          if (nextActiveThumb) {
            nextActiveThumb.classList.add('_size-md');
          }

          if (prevActiveThumb) {
            prevActiveThumb.classList.add('_size-md');
          }
        },
        slideNextTransitionStart: function () {
          const isSliderInit = this.slidesEl.dataset.init === 'false';

          if (isSliderInit) {
            this.slidesEl.setAttribute('data-init', 'true');
            return;
          }

          this.thumbs.swiper.slideNext();
        },
        slidePrevTransitionStart: function () {
          this.thumbs.swiper.slidePrev();
        },
      },
    });
  });
}
