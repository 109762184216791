export default function stickyHeader() {
  const header = document.querySelector('.header');
  const headerHeight = header.clientHeight;
  const FIXED_CLASS = '_fixed';
  const HIDDEN_CLASS = '_hidden';
  let scrollPosition = 0;
  let scrollDirection;

  const handleScroll = () => {
    const scrollDirectionUp = scrollDirection === 'up';
    const scrollDirectionDown = scrollDirection === 'down';
    const headerFixed = header.classList.contains(FIXED_CLASS);
    const offsetTop = document.body.getBoundingClientRect().top;

    scrollDirection = offsetTop > scrollPosition ? 'up' : 'down';
    scrollPosition = offsetTop;

    if (scrollDirectionUp && window.scrollY > headerHeight) {
      header.classList.remove(HIDDEN_CLASS);
      header.classList.add(FIXED_CLASS);
    }

    if (scrollDirectionDown && headerFixed) {
      header.classList.add(HIDDEN_CLASS);
      header.classList.remove(FIXED_CLASS);
    }

    if (scrollDirectionUp && window.scrollY < headerHeight + 5) {
      header.classList.add(HIDDEN_CLASS);
    }

    if (window.scrollY < headerHeight) {
      header.classList.remove(FIXED_CLASS);
      header.classList.remove(HIDDEN_CLASS);
    }
  };

  window.addEventListener('scroll', handleScroll);
}
