import jQuery from 'jquery';

export default function initHeaderOffset() {
  let container = jQuery('.offset-header');
  let header = jQuery('.header');

  function adjustHeightOffset() {
    const headerHeight = header.outerHeight();
    container.css('padding-top', headerHeight);
  }

  adjustHeightOffset();

  jQuery(window).on('resize', adjustHeightOffset);
}
